export const h5Router = [
  {
    path: "/h5", //首页
    name: "h5",
    component: () => import("../views/h5/h5_index.vue"),
    meta: { h5: true, active: 1 },
  },
  {
    path: "/h5_subjectGoods", //分类
    name: "h5_subjectGoods",
    component: () => import("../views/h5/h5_subjectGoods.vue"),
    meta: { h5: true, active: 2 },
  },
  {
    path: "/h5_customer", //客服
    name: "h5_customer",
    component: () => import("../views/h5/h5_customer.vue"),
  },
  {
    path: "/h5_search", //客服
    name: "h5_search",
    component: () => import("../views/h5/h5_search.vue"),
  },
  {
    path: "/h5_details", //详情
    name: "h5_details",
    component: () => import("../views/h5/h5_details.vue"),
  },
  {
    path: "/h5_details1", //详情
    name: "h5_details",
    component: () => import("../views/h5/h5_details1.vue"),
  },
  {
    path: "/h5_All_customer", //所有问题
    name: "h5_All_customer",
    component: () => import("../views/h5/h5_All_customer.vue"),
  },
  {
    path: "/h5_All_customerInfo", //问题解答
    name: "h5_All_customerInfo",
    component: () => import("../views/h5/h5_All_customerInfo.vue"),
  },
  {
    path: "/h5_redirectPagesIndex", //直播落地页
    name: "h5_redirectPagesIndex",
    component: () => import("../views/redirectPages/index.vue"),
  },
  {
    path: "/h5_redirectPagesNewActivity", //跳转支付宝商品详情的活动页面
    name: "h5_redirectPagesNewActivity",
    component: () => import("../views/redirectPages/newActivity.vue"),
  },
  {
    path: "/h5_redirectPagesNewActivity1", //跳转支付宝商品详情的活动页面
    name: "h5_redirectPagesNewActivity1",
    component: () => import("../views/redirectPages/newActivity1.vue"),
  },
  {
    path: "/h5_redirectPagesNewActivity2", //跳转支付宝商品详情的活动页面
    name: "h5_redirectPagesNewActivity2",
    component: () => import("../views/redirectPages/newActivity2.vue"),
  },
  {
    path: "/h5_prefectureActivity", //跳转支付宝商品详情的活动页面
    name: "h5_prefectureActivity",
    component: () => import("../views/redirectPages/prefectureActivity.vue"),
  },
  {
    path: "/h5_newPrefectureActivity", //跳转支付宝商品详情的活动页面
    name: "h5_newPrefectureActivity",
    component: () => import("../views/redirectPages/newPrefectureActivity.vue"),
  },
];
