<template>
  <div class="main" @mouseleave="(ismodalBox = false), (title = ''), (imgurl = '')" v-if="list">
    <div class="left">
      <li v-for="(item, index) in list" :key="item.id" @mouseenter="
        (ismodalBox = true),
        (active = index),
        (title = item.name),
        (imgurl = item.icon)
        ">
        <div style="display: flex; align-items: center">
          <img :src="item.icon" alt="" /><a :class="item.name == title ? 'active' : ''" href="javascript:void(0);">{{
            item.name }}</a>
        </div>
        <div class="leftIcon">>></div>
      </li>
    </div>
    <div class="center">
      <transition name="slide-fade">
        <div class="modalBox" v-if="ismodalBox" @mouseleave="(ismodalBox = false), (title = ''), (imgurl = '')">
          <div class="item">
            <div class="title">
              <img :src="imgurl" alt="" /><span>{{ title }}</span>
            </div>
            <li v-for="(item, index) in list[active].secondSubjectVOList" @click="toPath(item.id)">
              <a href="javascript:void(0);">{{ item.name }}</a>
            </li>
          </div>
        </div>
      </transition>
      <slideshow></slideshow>
      <div class="advantage">
        <li v-for="item in advantageList" :key="item.id">
          <div class="icon">
            <img :src="item.src" alt="" />
          </div>
          <p>{{ item.text }}</p>
        </li>
      </div>
    </div>
    <div class="right">
      <div class="qrcode">
        <qrcode
          :logoSrc="$pageConfig.title == '魅租机' ?logo1:logo2"
          logoBackgroundColor="#999"
          :logoMargin="5"
          :correctLevel="3"
          :text="qrcode"
          :logoScale="0.3"
          :size="230"
        ></qrcode>
        <!-- <img src="@/assets/indexQrcode/67tool (1).png" alt="" /> -->
      </div>
      <div class="info">
        <span>支付宝扫一扫 </span>
        <span style="color: #666">享受0押租凭首月1元租产品</span>
      </div>
    </div>
  </div>
</template>

<script>
import slideshow from "@/components/pc/mzj_slideshow.vue";
import { getAllSubject } from "@/api/indexApi";
import Qrcode from "vue-qr";
export default {
  name: "mzj_Main",
  components: {
    slideshow,
    Qrcode,
  },
  created() {
    // this.getList();
    // this.getSlideshow()
    this.qrcode =  `https://ds.alipay.com/?scheme=` +
        encodeURIComponent(
          `alipays://platformapi/startapp?appId=${this.$pageConfig.appId }&page=pages/index/index&query=linkId=60001`
        );
  },
  props: ['list'],//父组件传来的数据列表
  methods: {
    //根据id，跳转分类页
    toPath(id) {
      this.$router.push({
        path: "pc_subjectGoods",
        query: { getGoodsBySecondId: id },
      });
    },
    // getList() {
    //   //获取分类
    //   getAllSubject()
    //     .then((result) => {
    //       this.list = result.data.result.slice(0, 10);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
  },

  data() {
    return {
      qrcode:'',
      logo1: require("@/assets/meizuji.jpg"),
      logo2: require("@/assets/hz.jpg"),
      //模态框title
      title: "",
      imgurl: "",
      //模态框内容显示
      active: 0,
      //模态框默认关闭
      ismodalBox: false,
      //模态框假数据
      modalList: [
        "数据1",
        "数据2",
        "数据3",
        "数据4",
        "数据5",
        "数据6",
        "数据7",
        "数据8",
      ],
      //列表数据
      // list: [],
      //advantage列表
      advantageList: [
        { id: 1, text: '质量保证', src: "https://meizuly.oss-cn-shanghai.aliyuncs.com/xcxPic/website/zlbz.png" },
        { id: 2, text: '极致体验', src: "https://meizuly.oss-cn-shanghai.aliyuncs.com/xcxPic/website/gwc.png" },
        { id: 3, text: '售后无忧', src: "https://meizuly.oss-cn-shanghai.aliyuncs.com/xcxPic/website/sh.png" },
        { id: 4, text: '信用免押', src: "https://meizuly.oss-cn-shanghai.aliyuncs.com/xcxPic/website/zm.png" },
      ]
    };
  },
};
</script>
<style lang="scss" scoped>
.active {
  color: #e84534 !important;
}

.main {
  width: 1288px;
  height: 430px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .left {
    width: 252px;
    height: 430px;
    background-color: white;
    border-radius: 24px;

    li {
      padding: 0 17px 0 15px;
      height: 43px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      img {
        width: 18px;
        height: 18px;
      }

      a {
        font-size: 18px;
        font-family: "微软雅黑";
        font-weight: 400;
        color: #333333;
        margin-left: 12px;
      }
    }

    .leftIcon {
      font-size: 18px;
      font-family: "微软雅黑";
      font-weight: 400;
      color: #333333;
    }
  }

  .center {
    position: relative;
    width: 758px;
    height: 430px;
    background-color: white;
    border-radius: 24px;
    overflow: hidden;

    .modalBox {
      position: absolute;
      width: 420px;
      height: 430px;
      background: #ffffff;
      z-index: 99;
      padding: 0 25px;
      // border: 1px solid rgba($color: #dadada, $alpha: 0.9);
      border: 2px solid rgba($color: #dadada, $alpha: 1);
      border-radius: 25px;

      .item a:hover {
        color: #e84534;
      }

      .item {
        .title {
          display: flex;
          align-items: center;

          img {
            width: 20px;
            height: 20px;
          }

          span {
            font-size: 18px;
            font-family: "微软雅黑";
            font-weight: 400;
            color: #333333;
            line-height: 37px;
            margin-left: 12px;
          }
        }

        li {
          float: left;
          margin-right: 10px;
          font-size: 16px;
          font-family: "微软雅黑";
          font-weight: 400;
          color: #333333;
          line-height: 37px;
        }
      }
    }

    .advantage {
      width: 758px;
      height: 46px;
      margin-top: 29px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      li {
        width: 146px;
        height: 46px;
        background-color: rgba($color: #f78910, $alpha: 0.1);
        border-radius: 23px;
        display: flex;
        align-items: center;

        .icon {
          width: 36px;
          height: 36px;
          background: #f78910;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          margin-left: 8px;

          img {
            width: 20px;
            height: 20px;
          }
        }

        p {
          font-size: 16px;
          font-family: "微软雅黑";
          font-weight: 400;
          color: #ffa242;
          line-height: 24px;
          margin-left: 12px;
        }
      }
    }
  }

  .right {
    width: 252px;
    height: 430px;
    background-color: white;
    border-radius: 24px;

    .qrcode {
      margin: 88px 59px 0 49px;

      img {
        width: 160px;
        height: 160px;
      }
    }

    .info {
      width: 100%;
      height: 39px;
      font-size: 14px;
      font-weight: 400;
      color: #3a76f6;
      line-height: 24px;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin: 33px 0px 0 0px;
    }
  }
}

/*
  进入和离开动画可以使用不同
  持续时间和速度曲线。
*/
.slide-fade-enter-active {
  transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
