<template>
  <div id="app">
    <el-backtop v-if="$route.meta.pc"></el-backtop>
    <mzj_Header v-if="$route.meta.pc"></mzj_Header>
    <mzj_Nav v-if="$route.meta.pc"></mzj_Nav>
    <router-view></router-view>
    <mzj_Footer v-if="$route.meta.pc"></mzj_Footer>
    <h5_Footer v-if="$route.meta.h5"></h5_Footer>
  </div>
</template>
<script>
import mzj_Header from "@/components/pc/mzj_Header.vue";
import mzj_Nav from "@/components/pc/mzj_Nav.vue";
import mzj_Footer from "@/components/pc/mzj_Footer.vue";
import h5_Footer from '@/components/h5/h5_Footer.vue'
export default {
  name: "",
  components: {
    mzj_Header,
    mzj_Nav,
    mzj_Footer,
    h5_Footer
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    }
  },
  mounted() {
    if (this._isMobile()) {
      if (window.location.href.indexOf("h5") !== -1) return
      this.$router.replace("/h5");
    } else {
      if (window.location.href.indexOf("pc") !== -1) return
      if (window.location.href.indexOf("h5_redirectPagesNewActivity1") !== -1) return
      if (window.location.href.indexOf("h5_redirectPagesNewActivity2") !== -1) return
      this.$router.replace('/pc');
    }
  },
  watch: {
    $route(to, from) {
      this.path = this.$route.path;
      window.scrollTo(0, 0);
    }
  },
}

</script>
<style lang="scss">
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

#app {
  background-color: #f4f5f6;
}

a {
  text-decoration: none;
  color: #2e2e2e;
}

p a:hover {
  color: #487af8;
}</style>
