<template>
  <div class="home">
    <mzj_Main :list="list"></mzj_Main>
    <mzj_LIstVue :myList="myList"></mzj_LIstVue>
  </div>
</template>

<script>
// @ is an alias to /src
import mzj_Main from "@/components/pc/mzj_Main.vue";
import mzj_LIstVue from "@/components/pc/mzj_LIst.vue";
import mzj_RecommendVue from "@/components/pc/mzj_Recommend.vue";
import {getAllSubject} from "@/api/indexApi";
export default {
  name: "mzj_HomeView",
  components: {
    mzj_Main,
    mzj_LIstVue,
    mzj_RecommendVue,
},
  methods:{

  },
  created(){
    //获取分类
    getAllSubject().then((result) => {
      //main组件使用----同个接口父级一次调用即可
      this.list = result.data.result.slice(0, 10);
      //list组件使用----同个接口父级一次调用即可
      let newList=result.data.result.map(v=>{
        return {
          id:v.id,
          name:v.name,
          description:v.description,
          childList:v.subjectGoodsVOList.slice(0,5),//根据需求每个分类只需要5个即可
          image:v.image
        }
      })
      //映射出暂时需要的数据，子组件便不需要再做处理了
      this.myList=newList
    }).catch((err) => {
      console.log(err)
    });
  },
  data() {
    return {
      list:[],//用于接收传给mian组件的数据
      myList: [
      ],//用于接收传给list组件的数据
    };

  },
};
</script>
<style scoped>
.toTop{
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 100px;

  
}
</style>