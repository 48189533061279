<template>
  <div class="app">
    <div class="box_logo">
      <img
        :src="$pageConfig.logo"
        alt="" />
      <h1>
        <a href="javascript:void(0);" @click="toIndex">{{ $pageConfig.title }}</a>
      </h1>
    </div>
    <div class="container_search">
      <input type="text" @keyup.enter="toPath" v-model="searchData" />

      <div class="button" @click="toPath">
        <span>搜索</span>
      </div>
    </div>
  </div>
</template>

<script>
// import  {store}  from 'vuex';
export default {
  name: "mzj_Nav",
  data() {
    return {
      searchData: this.$store.state.searchData,
    };
  },
  watch: {
    //监听vuex的搜索数据
    "$store.state.searchData": {
      handler(newValue, oldValue) {
        console.log("nav新", newValue, "nav旧", oldValue);
        this.searchData = newValue;
      },
    },
  },
  methods: {
    //跳转主页
    toIndex() {
      //判断当前页面是否已经处于主页
      //当前页面不处于主页再跳转过去
      if (this.$route.path !== "/pc") {
        this.$router.push({ path: "pc" });
      }
    },
    toPath() {
      //判断是否为空
      if (this.searchData.trim() !== "") {
        this.$store.commit("changeSearchData", this.searchData);
        //判断当前页是不是搜索页------不是的话再跳转到搜索页去
        if (this.$route.path !== "/pc_searchSubjectGoods") {
          this.$router.push({ path: "pc_searchSubjectGoods" });
          sessionStorage.clear();
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.app {
  width: 1288px;
  height: 86px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .box_logo {
    display: flex;
    align-items: center;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    a {
      font-size: 30px;
      font-family: '微软雅黑';
      font-weight: 400;
      color: #3975f5;
      line-height: 46px;
      margin-left: 13px;
    }
  }

  .container_search {
    width: 916px;
    height: 46px;
    background: #f4f5f6;
    // border: 2px solid #3975f5;
    border-left: 2px solid #3975f5;
    border-top: 2px solid #3975f5;
    border-bottom: 2px solid #3975f5;
    border-radius: 23px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  input {
    flex: 1;
    border: none;
    height: 46px;
    background: #f4f5f6;
    border-radius: 23px;
    outline: none;
    font-size: 15px;
    text-indent: 2em;
  }

  .button :hover {
    // opacity: 0.5;
    cursor: pointer;
  }

  .button {
    width: 125px;
    height: 46px;
    background: #3975f5;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
  }
}
</style>
