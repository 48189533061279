import request from "./request"
export const getAllSubject=(v)=>request.get("/goods/subjectGoods/getAllSubject",v)
export const getArticleCategory=(v)=>request.get("/goods/subjectGoods/articleCategory/list",v)
export const getSubjectGoods=(v,x)=>request.get(`/goods/subjectGoods/getGoodsBySecondId/${v}`,{params: x})
export const getGoodsByName=(v)=>request.get(`/goods/subjectGoods/getGoodsByName`,{params: v})
export const getDetails=(v,x)=>request.get(`/goods/goods/get/${v}`,{params: x})
export const getCoupon=(x)=>request.get(`/promotion/coupon`,{params: x})
export const getActivityData=(x,y)=>request.get(`/page/zfb-act-page-config/${x}`,{params: y})
export const highPassingRateList=(x,y)=>request.get(`/setting/setting/highPassingRateList`,{params: y})
//传入token
export const highPassingRateListV2 = ( token) => request.get(`/setting/setting/highPassingRateList/v2`, {  headers: {
    accessToken: token
  }})