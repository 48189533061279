<template>
  <div class="box">
    <div class="content width1288">
      <div class="font">你好, 欢迎来到{{ $pageConfig.title }}</div>
      <div class="font">租赁热线：{{ $pageConfig.rentalTel }}    <span style="width: 10px;display: inline-block;"></span> 招商热线：{{$pageConfig.merchantTel}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mzj_Header",
};
</script>
<style lang="scss" scoped>
.width1288 {
  width: 1288px;
  margin: 0 auto;
}
.box {
  width: 100%;
  height: 39px;
background: white;
  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .font {
    font-size: 14px;
    font-family:'微软雅黑';
    font-weight: 400;
    color: #9395a0;
    line-height: 37px;
  }
}
</style>
