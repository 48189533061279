import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '@/views/pc/mzj_HomeView.vue'
import { h5Router } from './h5_index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/pc',//首页
    name: 'home',
    component: HomeView,
    meta: { pc:true }
  },
  {
    path: '/pc_subjectGoods',//分类
    name: 'subjectGoods',
    component: () => import('../views/pc/mzj_subjectGoodsView.vue'),
    meta: { pc:true }
  },
  {
    path: '/pc_searchSubjectGoods',//搜索
    name: 'searchSubjectGoods',
    component: () => import('../views/pc/mzj_SearchView.vue'),
    meta: { pc:true }
  },
  {
    path: '/pc_DetailsView',//详情
    name: 'DetailsView',
    component: () => import('../views/pc/mzj_DetailsView.vue'),
    meta: { pc:true }
  },
  {
    path: '/pc_DetailsView1',//信荣
    name: 'DetailsView',
    component: () => import('../views/pc/mzj_DetailsView1.vue'),
    meta: { pc:true }
  },
  // {
  //   path: '/AboutView',//详情
  //   name: 'AboutView',
  //   component: () => import('../views/pc/mzj_AboutView.vue')
  // },
  ...h5Router
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
