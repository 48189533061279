<template>
  <footer>
    <div class="tab">
      <div
        class="item"
        :class="item.id == active ? 'active' : ' '"
        v-for="item in tabList"
        :key="item.id"
        @click="toPath(item.url,active)"
      >
        <img :src="item.id == active ? item.active : item.img" alt="" />
        <span>{{ item.name }}</span>
      </div>
      <div
        class="item"
        @click="toFn"
      >
        <img src="@/assets/tabbar/wd1.png" alt="" />
        <span>我的</span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "",
  mounted(){
    let that=this
    if (sessionStorage.getItem("active")) {
      that.active=sessionStorage.getItem("active")
    }
    // 在页面刷新时将active保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem("active", that.active);
    //   alert(this.active)
    });
  },
    watch:{
    '$route.meta.active'(newValue,oldValue){
      console.log("isHot:newValue="+newValue,"oldValue="+oldValue);
       this.active=newValue
    }
},
  data() {
    return {
      active:this.$route.meta.active,
      tabList: [
        {
          id: 1,
          name: "主页",
          img: require("@/assets/tabbar/shouye1.png"),
          active: require("@/assets/tabbar/shouye2.png"),
          url:'/h5'
        },
        {
          id: 2,
          name: "分类",
          img: require("@/assets/tabbar/fenl1.png"),
          active: require("@/assets/tabbar/fenl2.png"),
          url:'/h5_subjectGoods'
        },
        // {
        //   id: 3,
        //   name: "客服",
        //   img: require("@/assets/tabbar/kf1.png"),
        //   active: require("@/assets/tabbar/kf2.png"),
        //   url:'/h5_customer'
        // },
        // {
        //   id: 3,
        //   name: "我的",
        //   img: require("@/assets/tabbar/wd1.png"),
        //   active: require("@/assets/tabbar/wd2.png"),
        //   url:'h5_search'
        // },
      ],
    };
  },
  methods: {
    //
       //跳支付宝
       toFn() {
      window.location.href =
        `https://ds.alipay.com/?scheme=` +
        encodeURIComponent(
          `alipays://platformapi/startapp?appId=${this.$pageConfig.appId }&page=pages/index/index&query=linkId=60001`
        );
    },
    toPath(url,active){
        if(this.$route.path != url){
            this.$router.push({path:url});
        }
    }
  },
};
</script>

<style lang="scss" scoped>
.active {
  color: #3976f5 !important;
}
footer {
  width: 100vw;
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  .tab {
    width: 90vw;
    height: 15vw;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .item {
      color: #ccc;
      width: 18vw;
      height: 15vw;
      margin: 2.25vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      img {
        width: 5vw;
        height: 5vw;
      }
      span {
        font-size: 3vw;
      }
    }
  }
}
</style>
