import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

Vue.prototype.$pageConfig = {
  title: process.env.VUE_APP_TITLE,
  logo: process.env.VUE_APP_LOGO,
  baseUrl: process.env.VUE_APP_BASEURL,
  appId: process.env.VUE_APP_APPID,
  rentalTel: process.env.VUE_APP_RENTALTEL,
  merchantTel: process.env.VUE_APP_MERCHANTTEL,
  serviceTel: process.env.VUE_APP_SERVICETEL,
  email: process.env.VUE_APP_EMAIL,
  companyName: process.env.VUE_APP_COMPANYNAME,
  address: process.env.VUE_APP_ADDRESS,
  filingNumber: process.env.VUE_APP_FILINGNUMBER,
  icp: process.env.VUE_APP_ICP,
};
window.document.title = process.env.VUE_APP_TITLE;
let link =
  document.querySelector("link[rel*='icon']") || document.createElement("link");
link.type = "image/x-icon";
link.href = process.env.VUE_APP_LOGO;
link.rel = "shortcut icon";
document.getElementsByTagName("head")[0].appendChild(link);
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
