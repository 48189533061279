<template>
   
    <div class="block">

      <el-carousel trigger="click" height="322px">
        <el-carousel-item v-for="item in carouselList" :key="item.id" >
            <a href="javascript:void(0)" @click="toPath(item.jumpType,item.jumpUrl)"><img :src="item.picture" alt="" class="myimg"></a>
        </el-carousel-item>
      </el-carousel>
    </div>
  </template>
  <script>
  import {  getArticleCategory } from "@/api/indexApi";
  export default{
    name:"mySlideshow",
    created(){
        this.getSlideshow()//调用获取轮播图
    },
    methods:{
      //轮播图跳转
      toPath(jumpType,jumpUrl){
        //不同类型跳转
        if(jumpType==1){
             this.$router.push({path:'pc_detailsView',query:{getGoodsBySecondId:jumpUrl}})
        }else if(jumpType==2){
          let _id=jumpUrl.match(/\d+/g)
          this.$router.push({path:'pc_subjectGoods',query:{getGoodsBySecondId:_id[1]}})
        }

      },
      getSlideshow(){
        //获取轮播图
      getArticleCategory().then((result) => {
        //筛选出顶部轮播图
         for (const v of result.data.result) {
                if(v.type == 'TOP_HOME_PAGE'){
                  this.carouselList.push(v)
                }
         }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    },
    data(){
      return{
        carouselList:[
        ]//轮播图列表
      }
    }
  }
</script>
  <style scoped>
  .myimg{
      border-radius: 24px;
  }
  img{
    width: 100%;
  }
    .el-carousel__item h3 {
      color: #475669;
      font-size: 14px;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
    }
  
    /* .el-carousel__item:nth-child(2n) {
       background-color: #99a9bf;
    }
    
    .el-carousel__item:nth-child(2n+1) {
       background-color: #d3dce6;
    } */
  </style>