<template>
  <div>
    <div class="list" v-for="(item, index) in myList" :key="index" v-if="item.childList.length>0">
      <header>
        <p @click="toPath('pc_subjectGoods', item.id)">
            {{ item.name }} <span class="span" v-if="item.description"> / <span>{{ item.description }}</span></span>
        </p>
      </header>
      <div
        class="list_child"
        v-for="(item1, index) in item.childList"
        :key="index"
        :class="index == 0 ? 'none' : ''"
        @click="toPath('pc_detailsView', item1.id)"
      >
        <div class="list_img">
          <img :src="item1.image" alt="" />
        </div>
        <div class="list_title">
          {{ item1.goodsName }}
        </div>
        <div class="list_price">
          <div style="display: flex;align-items: baseline;" class="price" v-if="item1.discountsDayPrice!==null&&Number(item1.discountsDayPrice)<Number(item1.dayPrice )">
            <span style="color: #E74734;">
              <span style="font-size: 18px;">{{ item1.discountsDayPrice  }}</span
            >/天
            </span>
            <span style="font-size: 12px;color: #999;">
              <span style="text-decoration: line-through;margin-left: 5px;">{{ item1.dayPrice }}</span
            >/天
            </span>
          </div>
          <div class="price" v-else style="color: #E74734;">
            <span style="font-size: 18px;">{{ item1.dayPrice }}</span
            >/天
          </div>
          <div class="rob"><a href="javascript:void(0)">立即抢租</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "mzj_List",
  props: ["myList"],//父组件传来的数据列表
  methods: {
    //跳转路由
    toPath(url, id) {
      this.$router.push({ path: url, query: { getGoodsBySecondId: id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.none {
  margin-left: 0px !important;
}
.list {
  width: 1288px;
  height: 390px;
  margin: 26px auto;
  header {
    font-size: 18px;
    font-family: "微软雅黑";
    font-weight: 400;
    color: #333333;
    line-height: 37px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p:hover{
      cursor:pointer;
    }
    .span {
      font-size: 12px;
      font-family: "微软雅黑";
      font-weight: 400;
      color: #999999;
      line-height: 37px;
      margin-left: 9px;
    }
  }
    .list_child:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
    cursor:pointer;
  }
  .list_child {
    width: 236px;
    height: 352px;
    background: #ffffff;
    border-radius: 13px;
    float: left;
    margin-left: 26px;
    margin-top: 5px;
    .list_img {
      width: 214px;
      height: 214px;
      background: #ffffff;
      margin: 11px 11px 0 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 214px;
        max-height: 214px;
        // width: 10px;height: 10px;
      }
    }
    .list_title {
      width: 213px;
      height: 48px;
      font-size: 18px;
      font-family: "微软雅黑";
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      margin: 9px 12px 0 11px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .list_price {
      width: 213px;
      height: 42px;
      margin: 18px 11px 0 11px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .price {
        // font-size: 16px;
        font-family: "微软雅黑"  !important;
        // font-weight: 400  !important;
        // color: #e74734;
        // line-height: 24px;
        // border: 1px solid red;
      }
      .rob {
        width: 80px;
        height: 32px;
        background: #f78910;
        border-radius: 6px;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          font-size: 14px;
          font-family: "微软雅黑";
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}
// .box {
//   width:1200px;
//   margin: 0 auto;
//   .list_child:hover{
//     box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
//     transform: scale(1.01);
//   }
//   .list {
//     width: 100%;
//     // height: 400px;

//     header{
//       display: flex;
//       align-items: center;
//       justify-content: space-between;
//       margin: 5px 0;
//       p{
//         background-color: white;
//         font-size: 12px;
//         border-radius: 5px;
//         padding: 10px;
//         border-radius: 5px;
//       }

//     }
//     h1 {
//     font-size: 18px;
//     margin: 10px 0;
//     span{
//       font-size: 8px;
//     }
//   }

//     .list_child {
//       width: 220px;
//       height: 320px;
//       float: left;
//       margin-left: 25px;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-around;
//       background-color: white;
//       border-radius: 10px;
//  div{
//     margin: 5px;
//  }
//       .list_img {
//         width: 200px;
//         margin:  0 auto;
//         img {
//           width: 100%;
//           height: 200px;
//         }
//       }
//       .list_title {
//         width: 200px;
//         margin: 0 auto;
//         text-overflow: -o-ellipsis-lastline;
//         overflow: hidden;
//         text-overflow: ellipsis;
//         display: -webkit-box;
//         -webkit-line-clamp: 2;
//         line-clamp: 2;
//         -webkit-box-orient: vertical;
//         font-size: 16px;
//       }
//       .list_price{
//         display: flex;
//         font-size: 12px;
//         align-items: center;
//         span{
//           font-size: 16px;
//           font-weight: 900;
//         }
//         a{
//           color:#E84534 ;
//         }
//         div{
//                 flex: 1;
//                 padding: 5px;
//                 height: 20px;
//                 line-height: 20px;
//                 text-align: center;
//         }
//       }
//     }
//   }
// }
</style>
