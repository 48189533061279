<!-- 添加中文注释 -->
<template>
  <footer>
    <div class="cooperation">
      {{ $tit }}
      <p>商户服务邮箱：{{ $pageConfig.email }}</p>
      <p>租赁热线：{{ $pageConfig.rentalTel }}</p>
      <p>招商热线：{{ $pageConfig.merchantTel }}</p>
    </div>
    <div class="address">
      <div>
        <p>
          <a href="javascript:void(0)" @click="toPath">{{ $pageConfig.filingNumber }}</a>
          {{ $pageConfig.companyName }}
          <a href="javascript:void(0)" @click="toPath" v-if="$pageConfig.icp">增值电信许可证：{{ $pageConfig.icp }}</a>
        </p>
        <p>开发者：{{ $pageConfig.companyName }}</p>
        <p>地址：{{ $pageConfig.address }}</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "mzj_Footer",
  data() {
    return {};
  },
  methods: {
    //跳转查看备案号网址
    toPath() {
      window.open(`https://beian.miit.gov.cn/`);
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  width: 100%;
  height: 280px;
  background-color: white;

  .cooperation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 920px;
    height: 17px;
    font-size: 16px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    margin: 0 auto;
    padding: 30px 0;
  }

  .address {
    width: 1288px;
    margin: 0 auto;
    padding: 30px 0;
    border-top: 1px solid #666;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      margin: 0 auto;
      width: 600px;
      height: 87px;
      font-size: 14px;
      font-family: "微软雅黑";
      font-weight: 400;
      color: #999999;
      line-height: 18px;

      a {
        font-size: 14px;
        font-family: "微软雅黑";
        font-weight: 400;
        color: #999999;
        line-height: 18px;
      }
    }
  }
}
</style>