<template>
  <div class="box">
    <h1>其他用户都在租</h1>
    <div class="content">
     <nav v-for="item,index in b=navList" :class="index==active?'active':' '" @click="active=index">
        <li>{{ item.title }}</li>
        <li>{{ item.className }}</li>
     </nav>
     
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {
        active:0,
        navList:[
            {title:"热门",className:"精品手机",
            childList: [
            {
              title:"MacBook Air 13.3英寸 轻薄本 256G 深空灰色笔记本电脑 MacBook Air13.3英寸 轻薄本 256G 深空灰色笔记本电脑",
              imgUrl:"https://alioss.woaizuji.com//image/oss/20549852675523464_33df9fddf6c24ccdb19dc719daed3923.png",
              price:666
            },
            {
              title:"MacBook Air 13.3英寸 轻薄本 256G 深空灰色笔记本电脑 MacBook Air13.3英寸 轻薄本 256G 深空灰色笔记本电脑",
              imgUrl:"https://alioss.woaizuji.com//image/oss/78614869757745373_b27ee5b736ff4482a4ed78a2803b9865.png",
              price:6666
            },
            {
              title:"MacBook Air 13.3英寸 轻薄本 256G 深空灰色笔记本电脑 MacBook Air13.3英寸 轻薄本 256G 深空灰色笔记本电脑",
              imgUrl:"https://alioss.woaizuji.com//image/oss/20549852675523464_33df9fddf6c24ccdb19dc719daed3923.png",
              price:6666
            },
            {
              title:"MacBook Air 13.3英寸 轻薄本 256G 深空灰色笔记本电脑 MacBook Air13.3英寸 轻薄本 256G 深空灰色笔记本电脑",
              imgUrl:"https://alioss.woaizuji.com//image/oss/78614869757745373_b27ee5b736ff4482a4ed78a2803b9865.png",
              price:6666
            },
          
          ],
        },
            {title:"热门",className:"精品手机"},
            {title:"热门",className:"精品手机"},
            {title:"热门",className:"精品手机"},
            {title:"热门",className:"精品手机"},
            {title:"热门",className:"精品手机"},
            {title:"热门",className:"精品手机"},
            {title:"热门",className:"精品手机"},

        ]
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.active{
    color: #E84534;
    li:last-child{
 
    color:white;
    background-color: #E84534 !important;
    }
}
.box {
  width: 80vw;
  margin: 0 auto;
  h1 {
    text-align: center;
  }
  .content {
    width: 100%;
    height: 60px;

    display: flex;
    align-items: center;
    background-color: white;
   margin: 10px;
    nav{
        width: 12.5%;
        height: 60px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    justify-content: space-around;
    font-size: 16px;
    li{
        width: 50%;
    }
        li:last-child{
    background-color: #F4F5F6;
    border-radius: 5px;
    padding: 5px;
    font-size: 12px;
    }
       
    }
    
  }
}
</style>
